// Migrated
<template lang="pug">
.w-100.position-relative.pb-print-5.d-flex.flex-column.justify-content-center.align-items-center.pt-print-5.z-3.d-print-none(
  :class="{ 'layout-head': !noMedia, 'pt-5': noMedia }"
)
  .position-absolute.top-0.right-0(v-if="bannerSpecial")
    LazySpecialBookmark(
      v-if="bannerSpecial.type === specials.bookmark"
      v-bind="bannerSpecial.data"
    )
    .pt-2.pt-md-3.my-n1.mr-n1.mr-md-0(v-else-if="bannerSpecial.type === specials.crown")
      LazyCrownText

  .banner-text-container.d-flex.flex-column.justify-content-top.align-items-center.w-100
    ResponsiveText.w-100.mb-2.text-center.word-break.banner-text-title.display-3(as="h1")
      span.text-white.font-weight-bold.text-shadow-dark(
        :data-i18n="bannerTitleI18n"
        data-allow-mismatch
      ) {{ bannerTitle }}

    h2.text-white.text-center.text-center.banner-text-subtitle.text-shadow-dark(
      v-if="bannerSubtitle && !bannerSubtitleColor"
      :class="bannerSubtitleClasses"
      :data-i18n="bannerSubtitleI18n"
    )
      | {{ bannerSubtitle }}
    .header-2xl.text-center.text-center.banner-text-subtitle.text-shadow-dark(
      v-if="bannerSubtitle && bannerSubtitleColor"
      :class="bannerSubtitleClasses"
    )
      | {{ bannerSubtitle }}

    .d-flex.align-items-center.drop-shadow-light.mb-2.mt-2.text-center(v-if="bannerStars?.score")
      LazyStars.mr-2(
        is-star
        :score="bannerStars?.score"
        :size="bannerStars.reviewCount ? 'sm' : 'lg'"
        type="inline"
      )
      span.text-white.ml-2.text-shadow-medium(
        v-if="bannerStars?.reviewCount"
      ) {{ $t('outOfReviews', { n: bannerStars.reviewCount }) }}

    .text-white.font-weight-bold.text-shadow-dark.text-center(v-if="bannerPrice || bannerDays")
      h2.serif-font.mb-0.d-inline.mr-2.pr-1.banner-text-subtitle(v-if="bannerDays") {{ bannerDays }}
      template(v-if="bannerPrice")
        .serif-font.mb-0.mr-2.d-inline-block {{ $t('shortFromPrice') }}
        h4.serif-font.mb-0.mr-2.d-inline-block.strikethrough.before-border-white(v-if="bannerPriceWithoutDiscount") {{ $n(bannerPriceWithoutDiscount) }}
        h3.serif-font.mb-0.mr-2.d-inline-block {{ $n(bannerPrice) }}

    template(v-if="bannerLink || bannerLinkButton")
      template(v-if="$slots.link")
        slot(name="link")

      nuxt-link.h4.mt-3.text-white.font-weight-medium.w-100.text-center(
          v-else-if="bannerLink"
          :to="bannerLink?.href || ''"
        )
        span.text-shadow-medium {{ bannerLink?.text }}
        fa.ml-3.mt-1.position-absolute(icon="arrow-right-long")

      button.h4.mt-3.py-2.px-4.text-white.font-weight-medium.text-center(
        v-else-if="bannerLinkButton"
        :class="bannerLinkButton?.bgColor ? `bg-${bannerLinkButton.bgColor}` : ''"
        @click="scrollToBanner(bannerLinkButton?.href)"
      )
        span.text-shadow-medium {{ bannerLinkButton?.text }}
</template>

<script setup>
import { specials } from '@layers/web/constants/banner'

defineProps({
  noMedia: {
    type: Boolean,
    default: false,
  },

  sidebarCollapsible: {
    type: Boolean,
    default: false,
  },
})

const rootStore = useRootStore()

const {
  pageBannerTitle: bannerTitle,
  pageBannerTitleI18n: bannerTitleI18n,
  pageBannerSubtitle: bannerSubtitle,
  pageBannerSubtitleI18n: bannerSubtitleI18n,
  pageBannerSubtitleColor: bannerSubtitleColor,
  pageBannerSubtitleWeight: bannerSubtitleWeight,
  pageBannerLink: bannerLink,
  pageBannerLinkButton: bannerLinkButton,
  pageBannerStars: bannerStars,
  pageBannerPrice: bannerPrice,
  bannerPriceWithoutDiscount: bannerPriceWithoutDiscount,
  pageBannerDays: bannerDays,
  pageBannerSpecial: bannerSpecial,
} = storeToRefs(rootStore)

const bannerSubtitleClasses = computed(() => {
  const defaultWeight = 'normal'

  const classes = [`font-weight-${bannerSubtitleWeight?.value || defaultWeight}`]

  if (bannerSubtitleColor?.value) {
    classes.push(`text-${bannerSubtitleColor.value}`)
  }

  return classes.join(' ')
})

const scrollToBanner = (bannerID) => {
  const findBanner = document.querySelector(`#${bannerID}`)

  if (findBanner) {
    findBanner.scrollIntoView({
      behavior: 'smooth',
    })
  }
}
</script>
