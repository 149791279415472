export const specials = {
  bookmark: 'bookmark',
  crown: 'crown',
}

/**
 * Used for preloading images
 * Cloudinary images goes in step of 250px in height
 * Update this if any changes may cause banner to preload another size of the banner image
 * Image width is calculated automatically
 */
export const BANNER_HERO_IMAGE_BREAKPOINTS = {
  small: {
    500: {
      height: 500,
    },
    1000: {
      height: 500,
    },
    1500: {
      height: 500,
    },
    1501: {
      height: 500,
    },
  },
  default: {
    500: {
      height: 1000,
    },
    1000: {
      height: 1000,
    },
    1500: {
      height: 1000,
    },
    1501: {
      height: 1000,
    },
  },
}
